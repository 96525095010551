
//引入依赖及组件
import Vue from 'vue'
import VueRouter from 'vue-router'
import Router1 from "@/views/router1.vue";
import home from "@/views/Home.vue";
import GoodsList from "@/views/goods/GoodsList.vue";
import OrderList from "@/views/order/OrderList.vue";

Vue.use(VueRouter);
//设置组件映射规则
const routes = [
    {
        path: '/',
        component: home,
        name: 'home'
    },
    {
        path: '/router2',
        component: Router1
    },
    {
        path: '/goodsList',
        name:'goodsList',
        component: GoodsList
    },
    {
        path: '/orderList',
        name:'orderList',
        component: OrderList
    },
]

const router = new VueRouter({
    mode:'history',
    routes
})
//导出
export default router;
