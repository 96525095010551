<template>
  <div>
    <hi>111111</hi>
  </div>
</template>

<script>

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'router1',
  data() {
    return {

    }
  },
  created() {

  },
  methods: {



  },
}
</script>

<style scoped>

</style>
