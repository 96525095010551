<template>
  <a-layout id="components-layout-demo-responsive" class="layoutHeight" >
    <a-layout-sider
        breakpoint="lg"
        collapsed-width="0"
        @collapse="onCollapse"
        @breakpoint="onBreakpoint"
    >

      <div class="logo">
           <div class="" >
             <img src="../../assets/Group430.png">
<!--             {{ $store.state.systemName }}-->
           </div>
      </div>
      <a-menu
              theme="dark"
               mode="inline"
              :default-selected-keys="['1']"
              @click="OnTitleMent"
      >
        <a-menu-item key="1" name="order">
          <a-icon type="appstore" />
          <span class="nav-text">订单管理</span>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="home"  />
          <span class="nav-text">店铺信息</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layou-heder :style="{ background: '#fff', padding: 0 }">
        <div class="headerHeight">
          <div>
            <div>
<!--              <img src="../../../public/favicon.ico">-->
            </div>
            <div class="nameCss">
              {{ ShopsInformation.name?ShopsInformation.name:'暂未找到商家信息' }}
            </div>
          </div>
        </div>
      </a-layou-heder>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
          <router-view></router-view>
        </div>
      </a-layout-content>
      <a-layout-footer style="textAlign: center">
       头号玩家 © 2024
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import OrderList from "@/views/order/OrderList.vue";
import GoodsList from "@/views/goods/GoodsList.vue";
import axios from "axios";
export default {
  name:'LayoutsIndex',
  // components: {OrderList, GoodsList},
  comments:{OrderList,GoodsList},
  data(){
    return {
      menuKey:1,
      ShopsInformation:{},//商户信息

    }
  },
  watch:{
    'menuKey':{
      immediate:true,
      deep:true,
      handler(newValue,OldValue){
        if(newValue==1){
          this.$router.push({
            name:'orderList'
          })
        }else if(newValue==2){
          this.$router.push({
            name:'goodsList'
          })
        }
        console.log(newValue,OldValue)
      }
    }
  },
  mounted() {
    console.log('这是布局里面的')
   console.log(this.$store.state.token)
    this.test()
  },
  methods: {
    test(){
      // eslint-disable-next-line no-unused-vars
      var urls = 'https://ready.player.370rpo.com/pdd/api/notify/get_data'
      axios({
        url:urls,
        method:'get',
        headers:{
          "Authorization":'Bearer '+localStorage.getItem('token')
        }
      }).then(res=>{
        if(res.data.status == 200){
          this.ShopsInformation = res.data.data;
        }else{
          this.$message.error(res.data.message)
          window.location.href = 'https://fuwu.pinduoduo.com/service-market/auth?response_type=code&client_id=8042105d1fc54b4a8b0ea50d07f23590&redirect_uri=https://ready.player.370rpo.com/pdd/api/notify/get_token';
        }
      })
    },
    onCollapse(collapsed, type) {
      console.log(collapsed, type);
    },
    onBreakpoint(broken) {
      console.log(broken);
    },
    OnTitleMent(collapsed){
      this.menuKey = collapsed.key;
    console.log(collapsed)
     //  console.log(this.menuKey)
    }
  },
};
</script>

<style>
#components-layout-demo-responsive .logo {
  height: 40px;
  margin: 30px 16px 16px 16px;
}
::-webkit-scrollbar{
  display: none;
}
.layoutHeight{
  height: 100vh;
  width: auto;
}
.systemNameClass{
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  line-height: 32px;
//background: linear-gradient(to right,#f0f2f5, #001529,#f0f2f5);
  border-radius: 3px;
  box-shadow: 3px 3px 3px #f5f5f5;
}
.headerHeight{
  height: 70px;

}
.nameCss{
 display: flex;
  height: 70px;
  line-height: 70px;
  justify-content: right;
  padding-right: 30px;
  color: #686e89;
  font-weight: bold;
}

</style>
