<template>
  <div>
    <a-card
        title="商户信息"
        :headStyle="headStyle"
    >
    </a-card>
    <a-row>
      <a-row class="rowHeight" >
        <a-col span="8" class="contentTitleCss">
          <div class="titleFlex">
            <div class="iconLeftRight">
              <a-icon type="shop" theme="twoTone" />
            </div>
            <div class="titleContent">
              商户名称
            </div>
          </div>
        </a-col>
        <a-col span="16" class="contentRemark">
          {{ShopsInformation.name?ShopsInformation.name:'暂无商户信息'}}
        </a-col>
      </a-row>
      <a-row class="rowHeight">
        <a-col span="8" class="contentTitleCss">
          <div class="titleFlex">
            <div class="iconLeftRight">
              <a-icon type="contacts" theme="twoTone" />
            </div>
            <div class="titleContent">
              拼夕夕商户昵称
            </div>
          </div>
        </a-col>
        <a-col span="16" class="contentRemark">
          {{ShopsInformation.owner_name?ShopsInformation.owner_name:'暂未设置名称'}}
        </a-col>
      </a-row>
      <a-row class="rowHeight">
        <a-col span="8" class="contentTitleCss">
          <div class="titleFlex">
            <div class="iconLeftRight">
              <a-icon type="idcard" theme="twoTone" />
            </div>
            <div class="titleContent">
              拼夕夕商户id
            </div>
          </div>
        </a-col>
        <a-col span="16" class="contentRemark">
          {{ShopsInformation.owner_id?ShopsInformation.owner_id:'暂未获取到商户Id'}}
        </a-col>
      </a-row>
      <a-row class="rowHeight">
        <a-col span="8" class="contentTitleCss">
          <div class="titleFlex">
            <div class="iconLeftRight">
              <a-icon type="cloud" theme="twoTone" />
            </div>
            <div class="titleContent">
              链接跳转
            </div>
          </div>
        </a-col>
        <a-col span="16" class="contentRemarkLink">
          <a :href="ShopsInformation.url">  <a-button type="primary"> Go forward<a-icon type="right" /> </a-button></a>
        </a-col>
      </a-row>
    </a-row>
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import axios from "axios";

export default {
  mixins:[JeecgListMixin],
  name: 'GoodsList',
  data() {
    return {
      headStyle:{
        color:'black',
        fontWeight: 'bold',
        fontSize:'18px'
      },
      ShopsInformation:{},//店铺信息
    }
  },
  created() {
    console.log('进入了商品界面')
    this.test()
  },
  methods: {
    test(){
      // eslint-disable-next-line no-unused-vars
      var urls = 'https://ready.player.370rpo.com/pdd/api/notify/get_data'
      axios({
        url:urls,
        method:'get',
        headers:{
          "Authorization":'Bearer '+localStorage.getItem('token')
        }
      }).then(res=>{
        if(res.data.status == 200){
          this.ShopsInformation = res.data.data;
        }else{
          this.$message.error(res.data.message)
          window.location.href = 'https://fuwu.pinduoduo.com/service-market/auth?response_type=code&client_id=8042105d1fc54b4a8b0ea50d07f23590&redirect_uri=https://ready.player.370rpo.com/pdd/api/notify/get_token';
        }
      })
    }
  },
}
</script>

<style scoped>
  .rowHeight{
    height: 50px;
    line-height: 50px;
    margin-top: 5px;
  }
  .contentTitleCss{
    background-color: rgb(245, 245, 245);
    text-align: left;
  }
  .titleFlex{
    display: flex;
  }
  .iconLeftRight{
    padding-left: 10px;
    padding-right: 5px;
  }
  .titleContent{
    color: #92959a;
    font-size: 18px;
  }
  .contentRemark{
    text-align: left;
    padding-left: 15px;
    border: 1px solid #f4f3f3;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 300;
  }
  .contentRemarkLink{
    text-align: right;
    padding-right:  15px;
    border: 1px solid #f4f3f3;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 300;
  }
</style>
