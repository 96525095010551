<template>
  <div>
    <a-modal
        :centered="true"
        :title="title"
        width="1100px"
        :visible="modal1Visible"
        @ok="() => setModal1Visible(false)"
        @cancel="() => setModal1Visible(false)"
    >
      <order-list-form ref="orderListForm"></order-list-form>
      <template slot="footer">
<!--        <a-button key="back" type="primary" @click="handleCancel">取消</a-button>-->
        <a-button key="submit"   @click="handleOk">关闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import OrderListForm from "@/views/order/modules/OrderListForm.vue";
export default {
  name:'OrderListModal',
  components: {OrderListForm},
  comments:{OrderListForm},
  props:{
    title:{
      type:String,
      default:'详情'
    }
  },
  data(){
    return {
      modal1Visible: false,
      modal2Visible: false,
    }
  },
  created() {

  },
  methods:{
    setModal1Visible(modal1Visible) {
      this.modal1Visible = modal1Visible;
    },
    cententFunction(Record){
      this.modal1Visible = true;
      this.$nextTick(()=>{
        this.$refs.orderListForm.getOrderListDetail(Record)
      })
    },
    //取消
    handleCancel(){
      this.modal1Visible = this.modal2Visible;
    },
    //关闭
    handleOk(){
      this.modal1Visible =this.modal2Visible;
    }
  }
}

</script>

<style>

</style>
