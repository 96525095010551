<template>
  <div>
    <div>
      <a-card
          title="订单检索"
          :headStyle="headStyle"
           :bordered="false"
           labelAlign="left"
      >
        <a-form
            :label-col="{span:3}"
            :wrapper-col="{span:16,offset:1}">
          <a-row>
            <a-col :xl="6" :lg="6" :md="7" :sm="24" >
              <a-form-item label="订单号" >
                <a-input placeholder="请输入订单号" v-model="params.order_sn" style="width: 100%"></a-input>
              </a-form-item>
            </a-col>
            <a-col :xl="6" :lg="6" :md="7" :sm="24" >
              <a-form-item label="姓名:">
                <a-input placeholder="请输入姓名" v-model="params.real_name" ></a-input>
              </a-form-item>
            </a-col>
            <a-col  :xl="6" :lg="7" :md="8" :sm="24" >
              <a-form-item label="手机号码:">
                <a-input v-model="params.user_phone" placeholder="请输入手机号" ></a-input>
              </a-form-item>
            </a-col>
            <a-col  :xl="6" :lg="7" :md="8" :sm="24" >
              <a-form-item label="状态:" >
                <a-select
                    v-model="params.status"
                    style="width: 100%"
                    placeholder="请选择订单状态"
                >
                  <a-select-option v-for="(StatusItem,StatusIndex) in orderStatus" :key="StatusIndex.id" :value="StatusItem.id">{{ StatusItem.name }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
           <a-row>
             <a-col :xl="6" :lg="7" :md="8" :sm="24">
               <a-form-item label="成交时间:">
                 <a-range-picker
                     v-model="params.create_time"
                     @change ="changeTime"
                     :locale = 'locale'
                 />
               </a-form-item>
             </a-col>
           </a-row>
          <a-row >
            <a-col :xl="6" :lg="7" :md="8" :sm="24">
            <span style="float: left;overflow: hidden;" class="table-page-search-submitButtons">
              <a-button style="background: #1F54FF" type="primary"  icon="search" @click="searchQuery">查询</a-button>
              <a-button style="color: #1F54FF;margin-left: 8px" type="primary"  ghost icon="reload" @click="restSearch" >重置</a-button>
            </span>
            </a-col>
          </a-row>
        </a-form>
      </a-card>
    </div>
    <div style="margin-top: 10px;border-top: 5px solid #f0f2f5;">
      <a-card
          :bordered="false"
      >
        <a-table
            :columns="columns"
            :data-source="data"
            :pagination="pagination"
            rowKey="order_id"
            @change="handleTableChange"
        >
        <span slot="num" slot-scope="text, record, index">
            {{ (ipagination.current - 1) * ipagination.pageSize + parseInt(index) + 1 }}
          </span>
          <span slot="customTitle"><a-icon type="smile-o" /> Name</span>
          <span slot="action" slot-scope="text, record">
             <a @click="orderDetail(record)" > 详情  </a>
            <a-divider type="vertical" v-show="record.status ==0 " />
            <a @click="print(record)" v-show="record.status ==0 ">打印 </a>
          </span>
          <span >
            <a-divider type="vertical" />
          </span>
        </a-table>
      </a-card>
    </div>
<!--组件-->
    <order-list-modal ref="orderListModal"></order-list-modal>
  </div>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';

// import http from '@/utils/request'
import axios from "axios";
import OrderListModal from "@/views/order/modules/OrderListModal.vue";
export default {
  name: 'OrderList',
  components: {OrderListModal},
  mixins:[JeecgListMixin],
  comments:{OrderListModal},
  data() {
    return {
      locale,
      columns:[
        {
          title: '序号',
          dataIndex: '',
          key:'rowIndex',
          width:60,
          align:"center",
          scopedSlots: {customRender: 'num'}
        },
        {
          title: '订单号',
          dataIndex: 'order_sn',
          key: 'order_sn',
          align:'center',
          scopedSlots: { customRender: 'order_sn' },
        },
        {
          title: '商品名称',
          dataIndex: 'product_name',
          key: 'product_name',
          align:'center',
        },
        {
          title: '姓名',
          dataIndex: 'real_name',
          align:'center',
          key: 'real_name',
        },
        {
          title: '手机号',
          dataIndex: 'user_phone',
          align:'center',
          key: 'user_phone',
        },
        {
          title: '地址',
          key: 'user_address',
          align:'center',
          dataIndex: 'user_address'
        },
        {
          title: '支付时间',
          key: 'pay_time',
          align:'center',
          dataIndex: 'pay_time'
        },
        {
          title: '创建时间',
          key: 'create_time',
          align:'center',
          dataIndex: 'create_time'
        },
        {
          title: '订单状态',
          key: 'status_text',
          align:'center',
          dataIndex: 'status_text'
        },
        {
          title: '操作',
          key: 'action',
          align:'center',
          scopedSlots: { customRender: 'action' },
        },
      ],
      params:{
        user_phone:'',//手机号
        order_sn:'',//订单号
        real_name:'',//姓名
        status:undefined,//状态
        create_time:[],//创建时间
        pickerTimeArray:[],
        per_page:10,
        page:1
      },
      data:[],
      orderStatus:[
        {
          id:0,
          name:'待发货'
        },
        {
          id:1,
          name:'待收货'
        },
        {
          id:2,
          name:'待评价'
        },
        {
          id:3,
          name:'已完成'
        },
        {
          id:9,
          name:'拼团中'
        },
        {
          id:10,
          name:'待付尾款'
        },
        {
          id:11,
          name:'尾款超时未付'
        },
        {
          id:-1,
          name:'已退款'
        }
      ],
      headStyle:{
        textAlign: 'left',
        fontWeight: 'bold',
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + "-" + range[1] + " 共" + total + "条"
        },
        showQuickJumper: true,
        showSizeChanger: false,
        total: 0
      },
      socket:'',//websocket 连接
      isWebSock:false
    }
  },
  watch:{
    $route:{
      handler(to,from){
        console.log('------------------')
        console.log(to,from)
      }
    }
  },
  created() {
    /*连接 WebSocket*/
    this.webSocketConnect()
    this.test()
  },
  beforeDestroy() {
    // 在组件销毁前关闭WebSocket连接
    this.socket.close();
  },
  methods: {
    //详情界面
    orderDetail(Item){
      this.$refs.orderListModal.title = '详情';
      this.$refs.orderListModal.cententFunction(Item)
    },
    /*连接webSocket*/
    webSocketConnect(){
        this.socket = new WebSocket('ws://127.0.0.1:13528');
      // 监听WebSocket收到消息事件
      // 监听WebSocket连接打开事件
      this.socket.onopen = () => {
        console.log('WebSocket连接已打开');
        this.isWebSock = true;
      };
      this.socket.onmessage = (event) => {
        // if(!event.data.docs.detail){
        //   this.$message.error('未安装打印插件请安装')
        // }
        console.log('收到消息：', event.data);
      };
      this.socket.onclose=()=>{
        console.log('WebSocket连接已关闭');
        this.isWebSock = false;
      }
    },
    //打印
      print(record){
        var urls = 'https://ready.player.370rpo.com/pdd/api/order/delivery'
        let params ={
          delivery_type:7,
          id:record.order_id,
          remark:'备注'
        }
        axios({
          url:urls,
          method:'POST',
          data:params,
          headers:{
            "Authorization":'Bearer '+localStorage.getItem('token')
          }
        }).then(res=>{
          if(res.data.status == 200){
            let pdf = res.data.data.pdf_file
             console.log(pdf)
            console.log('111111')
            if(!pdf){
              this.$message.error('pdf文件未返回')
              return
            }
            var value = {
              "cmd": "print",
              "requestID": "123458976545",
              "version": "1.0",
              "task": {
                "taskID": "7293666245",
                "printer": "",
                "printType": "dirctPrint",
                "previewType": "pdf",
                "documents": [
                  {
                    "documentID": "10000004",
                    "contents": [
                      {
                        "data": {
                          "nick": "张三"
                        },
                        "templateURL": pdf
                      }
                    ]
                  }
                ]
              }
            }
               // true 为已经链接  false为链接失败
               if(!this.isWebSock){
                 this.$message.error('未安装打印插件请安装')
               }
               this.socket.send(JSON.stringify(value))
                this.$message.success("打印成功")
                this.test()
          }else{
            this.$message.error(res.data.message)
            window.location.href = 'https://fuwu.pinduoduo.com/service-market/auth?response_type=code&client_id=8042105d1fc54b4a8b0ea50d07f23590&redirect_uri=https://ready.player.370rpo.com/pdd/api/notify/get_token';
          }
        })

    },
    handleTableChange(pagination, filters, sorter){
      console.log(pagination, filters, sorter)
      this.ipagination = pagination;
      this.pagination = pagination
      this.params.per_page = pagination.pageSize;
      this.params.page = pagination.current;
      this.test()
    },
    changeTime(e,w){
      this.params.create_time = w;
    },
    test(){
      // eslint-disable-next-line no-unused-vars
      var urls = 'https://ready.player.370rpo.com/pdd/api/order/list'
      // eslint-disable-next-line no-unused-vars
      var paramsArray = [];
      if(this.params.order_sn){
        paramsArray.push('order_sn='+this.params.order_sn)
      }
      if(this.params.user_phone){
        paramsArray.push('user_phone='+this.params.user_phone)
      }
      if(this.params.real_name){
        paramsArray.push('real_name='+this.params.real_name)
      }
      if(this.params.status){
        paramsArray.push('status='+this.params.status)
      }
      if(this.params.create_time.length>0){
        paramsArray.push('create_time='+JSON.stringify(this.params.create_time))
      }
      if(this.params.per_page){
        paramsArray.push('per_page='+this.params.per_page)
      }
      if(this.params.page){
        paramsArray.push('page='+this.params.page)
      }
      if(paramsArray.length>0){
        urls +='?'+paramsArray.join('&');
      }
      axios({
        url:urls,
        method:'get',
        headers:{
          "Authorization":'Bearer '+localStorage.getItem('token')
        }
      }).then(res=>{
        if(res.data.status == 200){
          this.data = res.data.data.data;
          this.pagination.total = res.data.data.total;
        }else{
          this.$message.error(res.data.message)
          window.location.href = 'https://fuwu.pinduoduo.com/service-market/auth?response_type=code&client_id=8042105d1fc54b4a8b0ea50d07f23590&redirect_uri=https://ready.player.370rpo.com/pdd/api/notify/get_token';
        }
      })
    },
    searchQuery(){
      this.test()
    },
    restSearch(){
      this.params = {
        user_phone:'',//手机号
        order_sn:'',//订单号
        real_name:'',//姓名
        status:undefined,//状态
        create_time:'',//创建时间
        page:1,
        per_page:10
      }
      this.pagination.current = 1;
      this.pagination.pageSize = 10
      this.searchQuery()
    }
  },
}
</script>

<style scoped>
 .dd{

 }
</style>
