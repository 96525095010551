<template>
  <div>
   <a-card>
     <a-form-model
         :label-col="{span:3}"
         :wrapper-col="{span:18,offset:1}"
     >
        <a-row>
          <a-col span="12">
            <a-form-model-item label="订单号">
              <a-input
                  v-model="information.order_sn"
                  placeholder="暂无订单号"
                  disabled="true"
                  style="width: 100%"
                 />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="姓名">
              <a-input
                  v-model="information.real_name"
                  placeholder="暂无姓名"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="手机号">
              <a-input
                  v-model="information.user_phone"
                  placeholder="暂无手机号"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>

          <a-col span="12">
            <a-form-model-item label="支付时间">
              <a-input
                  v-model="information.pay_time"
                  placeholder="暂无支付时间"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="创建时间">
              <a-input
                  v-model="information.create_time"
                  placeholder="暂无创建时间"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="订单状态">
              <a-input
                  v-model="information.status_text"
                  placeholder="暂无订单状态"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="支付价格">
              <a-input
                  v-model="information.pay_price"
                  placeholder="暂无支付价格"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="订单ID">
              <a-input
                  v-model="information.order_id"
                  placeholder="暂无订单ID"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
          <a-col span="12">
            <a-form-model-item label="地址">
              <a-textarea
                  v-model="information.user_address"
                  placeholder="暂无地址"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                  disabled="true"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
     </a-form-model>
     <a-row>
       <a-col span="12">

       </a-col>
     </a-row>
   </a-card>
  </div>
</template>
<script>
export default {
  name:'OrderListForm',
  data(){
    return {
      information:{},//订单信息
    }
  },
  mounted() {
  },
  created() {

  },
  methods:{
    getOrderListDetail(record){
      this.information = record;
    }
  }
}
</script>
<style>

</style>
