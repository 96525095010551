<template>
  <div id="app">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
<!--    <a-button type="primary">-->
<!--      Primary-->
<!--    </a-button>-->
<!--    <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--   -->
    <home></home>
  </div>
</template>

<script>
// import HelloWorld from './components/LayoutsIndex.vue'
import home from "@/views/Home.vue";
export default {
  name: 'App',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    home
  },
  created() {
    // console.log('这是App')
    // let tokens = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJwaHBlcjY2Ni9qd3QiLCJpZCI6MTMsIm5hbWUiOiJwZGQ2OTY4NDQzMzY5NCIsInVpZCI6bnVsbCwidXNlcl9pZCI6MCwib3duZXJfbmFtZSI6InBkZDY5Njg0NDMzNjk0Iiwib3duZXJfaWQiOjY5Njg0NDMzNiwidXJsIjoiaHR0cHM6Ly9mdXd1LnBpbmR1b2R1by5jb20vc2VydmljZS1tYXJrZXQvYXV0aD9yZXNwb25zZV90eXBlPWNvZGUmY2xpZW50X2lkPTgwNDIxMDVkMWZjNTRiNGE4YjBlYTUwZDA3ZjIzNTkwJnJlZGlyZWN0X3VyaT1odHRwczovL3JlYWR5LnBsYXllci4zNzBycG8uY29tL3BkZC9hcGkvbm90aWZ5L2dldF90b2tlbiIsImp3dF9zY2VuZSI6ImRlZmF1bHQiLCJqdGkiOiJkZWZhdWx0XzY2YzU0YmQ3ZGEyNWYwLjEwOTE4OTM3IiwiaWF0IjoxNzI0MjA2MDM5LCJuYmYiOjE3MjQyMDYwMzksImV4cCI6MTcyNDI5MjQzOX0.JRq1p2a0lTsHwIxtXGiYcaQQ4eCZ7-ZWMvV-KQS3ox8'
    // localStorage.setItem('token',tokens)
    let tokes = this.getUrlSearch('token');
    if(tokes){
      if(tokes != localStorage.getItem('token' && localStorage.getItem('token'))){
        this.$store.state.token = this.getUrlSearch('token')
        localStorage.setItem('token',this.getUrlSearch('token'))
      }
      if(!localStorage.getItem('token')){
        this.$store.state.token = this.getUrlSearch('token')
        localStorage.setItem('token',this.getUrlSearch('token'))
      }
    }else {
      window.location.href = 'https://fuwu.pinduoduo.com/service-market/auth?response_type=code&client_id=8042105d1fc54b4a8b0ea50d07f23590&redirect_uri=https://ready.player.370rpo.com/pdd/api/notify/get_token';
    }
  },
  destroyed() {
    localStorage.removeItem('token')
  },
  methods:{
    /* 获取地址栏参数 */
    getUrlSearch(name){
      if (!name) return null;
      // 查询参数：先通过search取值，如果取不到就通过hash来取
      var after = window.location.search;
      after = after.substr(1) || window.location.hash.split('?')[1];
      // 地址栏URL没有查询参数，返回空
      if (!after) return null;
      // 如果查询参数中没有"name"，返回空
      if (after.indexOf(name) === -1) return null;
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
      // 当地址栏参数存在中文时，需要解码，不然会乱码
      var r = decodeURI(after).match(reg);
      // 如果url中"name"没有值，返回空
      if (!r) return null;
      return r[2];
    },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
   //margin-top: 60px;
}
</style>
