<template>
  <div>
   <LayoutsIndex></LayoutsIndex>
  </div>
</template>

<script>
import LayoutsIndex from "@/components/layouts/LayoutsIndex.vue";

export default {
  name: 'HomeView',
  components:{LayoutsIndex },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {



  },
}
</script>

<style scoped>

</style>
